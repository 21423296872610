import { create } from "zustand";
import { getAccessToken, setTokens, clearTokens } from "services/tokenService";

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  login: (accessToken: string, refreshToken: string) => void;
  logout: () => void;
  setLoading: (loading: boolean) => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: !!getAccessToken(),
  loading: true,
  login: (accessToken, refreshToken) => {
    setTokens(accessToken, refreshToken);
    set({
      isAuthenticated: true,
      loading: false,
    });
  },
  logout: () => {
    clearTokens();
    set({
      isAuthenticated: false,
      loading: false,
    });
  },
  setLoading: (loading) => set({ loading }),
}));
