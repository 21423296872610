import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { Toaster } from "react-hot-toast";

import { PrivateRoute } from "components/auth/PrivateRoute";
import { PublicRoute } from "components/auth/PublicRoute";
import { useAuthStore } from "store/authStore";
import "./scss/style.scss";

const Login = lazy(() => import("views/pages/login/Login"));
const Register = lazy(() => import("views/pages/register/Register"));
const Onboarding = lazy(() => import("views/pages/onboarding/Onboarding"));
const GetStarted = lazy(() => import("views/pages/getStarted/GetStarted"));
const EnterCompanyCode = lazy(
  () => import("views/pages/enterCompanyCode/EnterCompanyCode")
);
const Feedback = lazy(() => import("views/pages/feedback/Feedback"));
const SelectYourUnit = lazy(
  () => import("views/pages/selectYourUnit/SelectYourUnit")
);
const ShowCompanyCode = lazy(
  () => import("views/pages/showCompanyCode/ShowCompanyCode")
);

const NotFound = lazy(() => import("views/pages/notFound/NotFound"));

const DefaultLayout = lazy(() => import("layout/DefaultLayout"));

const App = () => {
  const setLoading = useAuthStore((state) => state.setLoading);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useEffect(() => {
    // Short timeout to ensure store is hydrated
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [setLoading]);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <BrowserRouter>
      <Toaster />
      <Suspense fallback={loading}>
        <Routes>
          {/* Public routes that redirect to dashboard if authenticated */}
          <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
            <Route index element={<EnterCompanyCode />} />
            <Route path="enter-company-code" element={<EnterCompanyCode />} />
            <Route path="backend" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Route>

          {/* Public routes that don't redirect (even if authenticated) */}
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="get-started" element={<GetStarted />} />
          <Route path="show-company-code" element={<ShowCompanyCode />} />
          <Route path="select-your-unit" element={<SelectYourUnit />} />
          <Route path="feedback" element={<Feedback />} />

          {/* Protected routes */}
          <Route
            path="app"
            element={<PrivateRoute isAuthenticated={isAuthenticated} />}
          >
            <Route index element={<Navigate to="company-master" replace />} />
            <Route path="*" element={<DefaultLayout />} />
          </Route>

          {/* 404 route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
