import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface PublicRouteProps {
  redirectPath?: string;
  children?: ReactElement;
  isAuthenticated?: boolean;
}

export const PublicRoute = ({
  redirectPath = "/app/company-master",
  isAuthenticated,
  children,
}: PublicRouteProps) => {
  if (isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
