import { ReactElement, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "store/authStore";

interface PrivateRouteProps {
  redirectPath?: string;
  isAuthenticated: boolean;
  children?: ReactElement;
}

export const PrivateRoute = ({
  redirectPath = "/backend",
  isAuthenticated,
  children,
}: PrivateRouteProps) => {
  const { loading, setLoading } = useAuthStore();

  useEffect(() => {
    if (isAuthenticated && loading) {
      setLoading(false);
    }
  }, [isAuthenticated, loading, setLoading]);

  if (loading) {
    return (
      <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
